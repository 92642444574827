import type { LogsEvent } from '@datadog/browser-logs'
import { datadogLogs } from '@datadog/browser-logs'
import type { RumEvent } from '@datadog/browser-rum-slim'
import { datadogRum } from '@datadog/browser-rum-slim'

import type { RootContext } from './hooks/use-root-context'

// Partial matches for error messages we want to ignore
const IGNORED_FRONTEND_ERRORS = ['Minified React error #423', 'Hydration failed']

export const initDatadog = (config: { env: string; version: string }) => {
  const beforeSend = (event: RumEvent | LogsEvent) => {
    const message = event.message
    if (event.error && typeof message === 'string' && IGNORED_FRONTEND_ERRORS.some((ignoredError) => message.includes(ignoredError))) {
      return false
    }
  }

  // Only run if Datadog RUM hasn't been initialized already
  if (!datadogRum.getInitConfiguration()) {
    datadogRum.init({
      applicationId: '53e945b3-d105-4b25-9ea5-e610fa8776af',
      clientToken: 'pubc100204be602f60c1b3f7874a85eefa4',
      site: 'datadoghq.com',
      service: 'revere',
      env: config.env,
      version: config.version,
      sampleRate: 100,
      trackInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      allowedTracingUrls: ['http://localhost:3000', 'https://app.indigov.com', 'https://app-staging.indigov.com'],
      telemetrySampleRate: 0,
      beforeSend
    })
  }

  if (!datadogLogs.getInitConfiguration()) {
    datadogLogs.init({
      clientToken: 'pub450628470ba99e3f7d02ab3f6c2d5272',
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
      site: 'datadoghq.com',
      service: 'revere',
      env: config.env,
      version: config.version,
      forwardConsoleLogs: 'all',
      telemetrySampleRate: 0,
      beforeSend
    })
  }
}

export const setDatadogMeta = (context: RootContext) => {
  datadogRum.setUser({
    id: context.currentUser?.id,
    email: context.currentUser?.email
  })

  datadogRum.setGlobalContextProperty('meta', { customer: context.customer })

  datadogLogs.setUser({
    id: context.currentUser?.id,
    email: context.currentUser?.email
  })

  datadogLogs.setGlobalContextProperty('meta', { customer: context.customer })
}
